<template>
  <v-row class="">
    <v-col cols="12" md="6">
      <v-select
        v-model="dateRangeSelect"
        :items="$t('DateRangeSelect')"
        :label="$t('DateRangeSelector')"
        persistent-hint
        :filled="filled"
        :outlined="outlined"
        :dense="dense"
        hide-details
        single-line
      ></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :dense="dense"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-bind="attrs"
            :filled="filled"
            :outlined="outlined"
            v-on="on"
            :disabled="isDisabled"
            :dense="dense"
          ></v-text-field>
          <!--Datum Od: {{ dateRange[0] }}
        Dátum Do: {{ dateRange[1] }}-->
        </template>
        <!-- v-date-picker to first select start date in past and then select maximum range 2 months -->

        <v-date-picker
          :value="value"
          no-title
          range
          scrollable
          @input="updateValue($event)"
          :min="minDate"
          :max="maxDate"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(dateRange)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import * as moment from "moment/moment";

export default {
  name: "v-date-range-picker",
  props: {
    value: {
      type: Array,
      default: [],
    },
    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadingData: false,
      //DateRangePicker - vyber rozsahu datumov pre zoznam
      dateRange: this.value,
      menu: false,
      modal: false,
      menu2: false,
      dateRangeSelect: "today",
      //minDate is 10 years in the past
      minDate: new Date(new Date().getTime() - 10 * 365 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
      //maxDate is tomorrow
      maxDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
    };
  },

  mounted() {
    //console.log("DateRangePicker.vue.mounted.setDateRange: ", this.value);
    var dateRangeSelect = this.getCookie("dateRangeSelect");
    if (dateRangeSelect && dateRangeSelect != null) {
      this.dateRangeSelect = JSON.parse(dateRangeSelect);
    }
    var dateRange = this.getCookie("dateRange");
    if (dateRange && dateRange != null) {
      if (this.dateRangeSelect == "custom") {
        this.dateRange = JSON.parse(dateRange);
      }
    }
    this.setDateRange();
  },

  computed: {
    dateRangeText() {
      return this.dateRange.join(" ~ ");
    },
    isDisabled: function () {
      console.log(
        "DateRangePicker => isDisabled => this.dateRangeSelect",
        this.dateRangeSelect
      );
      return this.dateRangeSelect != "custom";
    },
  },

  watch: {
    dateRangeSelect: function (val) {
      console.log("som sa zmenil!!!");
      console.log(
        "DateRangePicker => watch => this.dateRangeSelect",
        this.dateRangeSelect
      );
      this.setDateRange();
    },
  },

  methods: {
    setDateRange() {
      var dateFrom;
      var dateTo;
      var day = new Date();
      //day = new Date(day - new Date().getTimezoneOffset() * 60000);
      /*var today = moment().startOf("day");
      var yesterday = moment().subtract(1, "days").startOf("day");
      var lastWeek = moment().subtract(7, "days").startOf("day");
      var lastMonth = moment().subtract(1, "months").startOf("day"); 
      console.log("DateRangePicker.setDateRange.today: ", today);
      console.log("DateRangePicker.setDateRange.yesterday: ", yesterday);
      console.log("DateRangePicker.setDateRange.lastWeek: ", lastWeek);
      console.log("DateRangePicker.setDateRange.lastMonth: ", lastMonth);
      console.log("DateRangePicker => setDateRange => this.dateRangeSelect",this.dateRangeSelect);*/

      switch (this.dateRangeSelect) {
        case "today":
          dateFrom = day;
          dateTo = day;
          break;
        case "yesterday":
          dateFrom = day.setDate(day.getDate() - 1);
          dateTo = day;
          break;
        case "last_2_days":
          dateFrom = day.setDate(day.getDate() - 1);
          dateTo = new Date();
          break;
        case "this_week": //week starts on Monday
          let today = new Date();
          let weekday = today.getDay(); // 0 pre nedeľu, 1 pre pondelok, atď.
          let daysUntilMonday = weekday === 0 ? 6 : weekday - 1; // Ak je dnešok nedeľa, potrebujeme sa posunúť o 6 dní späť na pondelok.
          dateFrom = new Date(today);
          dateFrom.setDate(today.getDate() - daysUntilMonday);
          dateTo = new Date(dateFrom);
          dateTo.setDate(dateFrom.getDate() + 6);
          break;
        case "this_month":
          dateFrom = day.setDate(1);
          day.setMonth(day.getMonth() + 1);
          dateTo = day.setDate(day.getDate() - 1);
          break;
        case "last_month":
          day.setMonth(day.getMonth() - 1);
          dateFrom = day.setDate(1);
          day.setMonth(day.getMonth() + 1);
          dateTo = day.setDate(day.getDate() - 1);
          break;
        case "last_3_months":
          day.setMonth(day.getMonth() - 3);
          dateFrom = day.setDate(1);
          day = new Date();
          day.setDate(1);
          day.setMonth(day.getMonth() + 1);
          dateTo = day.setDate(day.getDate() - 1);
          break;
        case "last_6_months":
          day.setMonth(day.getMonth() - 6);
          dateFrom = day.setDate(1);
          day = new Date();
          day.setDate(1);
          day.setMonth(day.getMonth() + 1);
          dateTo = day.setDate(day.getDate() - 1);
          break;
        case "custom":
          if (!this.dateRange) {
            dateFrom = day;
            dateTo = day;
          }
          break;
        default:
          dateFrom = day;
          dateTo = day;
          break;
      }
      if (
        this.dateRangeSelect != "custom" ||
        (!this.dateRange && this.dateRangeSelect == "custom")
      ) {
        this.dateRange = [
          new Date(dateFrom - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          new Date(dateTo - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        ];
      }
      console.log(this.dateRange);
      this.updateValue();
    },
    updateValue(value) {
      if (value) {
        // Nastavenie po vyber prveho datumu
        if (value[0] && !value[1]) {
          var maxDate = new Date(value[0]);
          maxDate.setMonth(maxDate.getMonth() + 2);
          //if is maxDate in the future, set it to tomorrow
          if (new Date(maxDate) > new Date()) {
            //set maxdate to tomorrow
            maxDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
          }
          this.maxDate = maxDate.toISOString().substr(0, 10);
          //nastavenie zvoleneho datumu zaroven ako minDate
          this.minDate = value[0];
        }
        // Nastavenie po vyber druheho datumu (zresetovanie na default)
        if (value[0] && value[1]) {
          //minDate is 10 years in the past
          (this.minDate = new Date(
            new Date().getTime() - 10 * 365 * 24 * 60 * 60 * 1000
          )
            .toISOString()
            .substr(0, 10)),
            //maxDate is tomorrow
            (this.maxDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              .toISOString()
              .substr(0, 10));
        }
      }

      if (value) {
        this.dateRange = value;
      }
      this.$emit("input", this.dateRange);
      //Ulozenie hodnot do cookies, pre nacianie z inej stranky alebo pri navrate
      this.setCookie(
        "dateRangeSelect",
        JSON.stringify(this.dateRangeSelect),
        14
      );
      this.setCookie("dateRange", JSON.stringify(this.dateRange), 14);
    },

    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      nameC = "DateRangePicker_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      nameC = "DateRangePicker_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },

    //Vymazanie cookie
    eraseCookie(nameC) {
      nameC = "DateRangePicker_" + nameC;
      document.cookie =
        nameC + "=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
  },
};
</script>
